<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

/**
 * Starter page
 */
export default {
  components: { Layout, PageHeader },
  page: {
    title: "Starter Page",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      title: "Londex Wheel",
      items: [
        {
          text: "Londex",
        },
        {
          text: "Home", 
          active: true,
        },
      ],
    };
  },
  middleware: "authentication",
  methods: {
    navigateTo: function(to) {
      this.$router.push(to);
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
        <div class="col-12 col-lg-9">
            <div class="card">
                <!-- <div class="card-body"> -->
                    <!-- <div class="float-end mt-2">
                        <img src="https://icons.iconarchive.com/icons/cjdowner/cryptocurrency-flat/1024/Binance-Coin-BNB-icon.png" alt="" height="70">
                    </div>
                    <div>
                        <h4 class="mb-1 mt-1">
                            $
                            <span data-plugin="counterup">
                                <countTo :startVal="0" :endVal="jackpotBalanceUsd" :duration="2000" :decimals="2"></countTo>
                            </span>
                        </h4>
                        <p class="text-muted mb-0">Jackpot Balance</p>
                    </div>
                    <p class="text-muted mt-3 mb-0" v-if="jackpotBalance">
                        {{ jackpotBalance.toFixed(2) }} BNB
                    </p>
                    <p class="text-muted mt-3 mb-0" v-else>
                        0.00 BNB
                    </p> -->
                    <img :src="require('@/assets/input.png')" class="img-fluid" alt="">
                <!-- </div> -->
            </div>
        </div>
        <!-- end col-->
    </div>
    <div class="row">
      <div class="col-12 col-lg-3">
          <div class="card text-center">
            <div class="card-body">
              <br/>
              <div class="clearfix"></div>
              <div class="mb-4">
                <img
                  :src="require('@/assets/ticket.png')"
                  height="80"
                />
              </div>
              <p class="font-size-20 mb-1">
                <a href="#/low-roller" class="text-dark">0,025 BNB</a>
              </p>
            </div>
            <div class="btn-group" role="group">
              <button type="button" class="btn btn-outline-light text-truncate" @click="navigateTo('/low-roller')">
                <i class="uil uil-trophy me-1"></i> PLAY
              </button>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-3">
          <div class="card text-center">
            <div class="card-body">
              <br/>
              <div class="clearfix"></div>
              <div class="mb-4">
                <img
                  :src="require('@/assets/ticket.png')"
                  height="80"
                />
              </div>
              <p class="font-size-20 mb-1">
                <a href="#/wheel" class="text-dark">0,1 BNB</a>
              </p>
            </div>
            <div class="btn-group" role="group">
              <button type="button" class="btn btn-outline-light text-truncate" @click="navigateTo('/wheel')">
                <i class="uil uil-trophy me-1"></i> PLAY
              </button>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-3">
          <div class="card text-center">
            <div class="card-body">
              <br/>
              <div class="clearfix"></div>
              <div class="mb-4">
                <img
                  :src="require('@/assets/ticket.png')"
                  height="80"
                />
              </div>
              <p class="font-size-20 mb-1">
                <a href="#/whale-roller" class="text-dark">0,5 BNB</a>
              </p>
            </div>
            <div class="btn-group" role="group">
              <button type="button" class="btn btn-outline-light text-truncate" @click="navigateTo('/whale-roller')">
                <i class="uil uil-trophy me-1"></i> PLAY
              </button>
            </div>
          </div>
        </div>
    </div>
  </Layout>
</template>
